import { isArray, isObject } from "lodash";

class VarHelper {
  removeUndefinedField = (obj) => {
    for (let key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }
  removeUndefinedNullField = (obj) => {
    for (let key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }
  searchInArray = (arr: any[], keyword: string) => {
    if (!keyword) return arr;
    if (!isArray(arr)) return arr;
    const result = [];
    for (let item of arr) {
      const searchKey = keyword.toLowerCase();
      if (isObject(item)) {
        if (JSON.stringify(item).toLowerCase().includes(searchKey)) {
          result.push(item);
        }
      } else {
        if (String(item).toLowerCase().includes(keyword.toLowerCase())) {
          result.push(item);
        }
      }
    }
    return result;
  }
  parseKeysToArr = (str: string) => {
    if (!str) return [];
    return str.split(',').map(i => String(i).trim().toLowerCase())
  }

  replacePlaceholders = (inputString, replacements) => {
    if (!inputString || !replacements) return inputString;
    const regexPattern = /{{\s*\.(\w+)\s*}}/g;
    return inputString.replace(regexPattern, (match, placeholder) => {
      return replacements.hasOwnProperty(placeholder) ? replacements[placeholder] : match;
    });
  }

  isSameDomainAndPath = (url1, url2) => {
    const domain1 = (new URL(url1)).hostname;
    const domain2 = (new URL(url2)).hostname;
    const path1 = (new URL(url1)).pathname;
    const path2 = (new URL(url2)).pathname;
    return domain1 === domain2 && (path1.includes(path2) || path2.includes(path1));
  }
}

export default new VarHelper();
