import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';


export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
}

export const ASSETS = {
  LOGO: require('./img/pathfinder-logo.png'),
  CUSTOMER_ADDRESS_ICON: require('./img/address.png'),
  LOGIN_BG: require('./img/login-bg.jpg'),
  FORM_BG: require('./img/form-bg.png'),
  GRADIENT_MASK: require('./img/gradient-mask.png'),
  SLIDER_THUMB: require('./img/slider-thumb.png'),
  SLIDER_TRACK: require('./img/slider-track.png'),
}
