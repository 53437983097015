import { Col, Grid, Image, Row, Text, Slider } from "components/base";
import { COLOR } from "const";
import React, { useState } from "react";
import { ASSETS } from "assets";
import { useDynamicResponsiveValue } from "quickly-react";
import { Radio } from "components/elements";
import { View, useWindowDimensions } from "react-native";

interface Props {
  hideBorder?: boolean
  statement?: string
  value: number
  onChange: (v: number) => void
  rolloverText?: string
  likertScaleTitles?: string[]
}

const Triangle = ({ index }: any) => (
  <View
    style={{
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: 7,
      borderRightWidth: 7,
      borderBottomWidth: 9,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: '#00AAB4',
      alignSelf:
        index === 0 ? 'flex-start'
          : (index === 1 ? 'center' : 'flex-end'),
    }}
  />
)

const StatementItem = (props: Props) => {
  const { hideBorder, statement, value, onChange, rolloverText, likertScaleTitles } = props;
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const { width } = useWindowDimensions();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Grid
      xs='100%' md='6:4'
      // borderColor={COLOR.DIVIDER} borderBottomWidth={hideBorder || breakpoint === 'xs' ? 0 : 1}
      paddingBottom={breakpoint !== 'xs' ? 12 : 8} paddingTop={8}
    >
      <Col paddingRight={breakpoint !== "xs" ? 36 : undefined}>
        <Text fontSize={16} fontWeight={'500'}>{statement}</Text>
      </Col>
      {breakpoint !== 'xs' ?
        <Col ml3 paddingHorizontal={16}>
          <Col absolute top={7} left={21} right={21}>
            <Image
              source={ASSETS.SLIDER_TRACK}
              style={{ width: '100%', height: 7 }}
              resizeMode="stretch"
            />
          </Col>
          <Slider
            minimumValue={0}
            maximumValue={2}
            minimumTrackTintColor="transparent"
            maximumTrackTintColor="transparent"
            thumbTintColor=''
            step={1}
            value={value}
            onValueChange={onChange}
            style={{ height: 22 }}
            // @ts-ignore
            thumbSize={30}
            thumbImage={ASSETS.SLIDER_THUMB}
            nativeID="liker-slider"
            onMouseOverThumb={() => setShowTooltip(true)}
            onMouseOutThumb={() => setShowTooltip(false)}
            tooltipText={rolloverText}
          />
          {/* {!!rolloverText && showTooltip &&
            <Col mt1 position={'absolute'} zIndex={99} left={14} right={14} top={12} pointerEvents="none">
              <Col paddingHorizontal={10}>
                <Triangle index={value} />
              </Col>
              <Col
                backgroundColor={'#00AAB4'} borderRadius={8}
                paddingHorizontal={16} paddingVertical={8}
              >
                <Text color={COLOR.WHITE}>{rolloverText}</Text>
              </Col>
            </Col>
          } */}
        </Col>
        :
        <Col marginTop={8}>
          {[0, 1, 2].map(i => (
            <Radio
              text={likertScaleTitles[i]} selected={value === i}
              key={`${statement}${i}`} marginVertical={8}
              onPress={() => onChange(i)}
            />
          ))}
          {!!rolloverText &&
            <Col
              backgroundColor={'#00AAB4'} borderRadius={8}
              paddingHorizontal={16} paddingVertical={8}
              mt1
            >
              <Text color={COLOR.WHITE}>{rolloverText}</Text>
            </Col>
          }
        </Col>
      }
    </Grid>
  )
}

export default StatementItem;
