import React from "react";
import { Col, Image, Row, Text } from "components";
import { Tooltip } from 'react-tippy';

interface Props {
  name?: string
  rolloverText?: string
  image?: string
  onPress?: () => void
  hideName?: boolean
}

const ElementItem = (props: Props) => {
  const { name, rolloverText, image, onPress, hideName } = props;
  return (
    <Tooltip title={rolloverText} className="element-rollover" trigger="mouseenter">
      <Col
        // shadow
        // borderWidth={1} borderRadius={8}
        // borderColor={'rgba(0, 0, 0, 0.09)'}
        marginBottom={21}
        marginRight={24}
        dataSet={{ element: 'element-item' }}
        onPress={onPress}
        overflow={'hidden'}
      >
        <Image
          source={{ uri: image }}
          style={{ width: '100%', aspectRatio: 4 / 3, borderRadius: 8 }}
          resizeMode='cover'
        />
        {!hideName &&
          <Text fontSize={18} fontWeight={'600'} marginTop={8}>{name}</Text>
        }
      </Col>
    </Tooltip>
  )
}

export default ElementItem;
