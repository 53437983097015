import React from 'react';
import Navigation from './src/navigation/Navigation';
import { ConfirmModal, ModalContainer } from './src/components';
import 'react-tippy/dist/tippy.css';
import './App.css';

export default function AppWeb() {
  return (
    <ModalContainer>
      <ConfirmModal>
        <Navigation />
      </ConfirmModal>
    </ModalContainer>
  );
}