import { Text, LearnerLayout, Row, Button, MainContentContainer, StepsHorizontal, Col, StatementItem, Grid, Image, modalConfirm } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import shallow from 'zustand/shallow';
import { ActivityIndicator, StyleSheet, useWindowDimensions } from 'react-native';
import { ASSETS } from 'assets';
import { VarHelper } from 'helpers';
import { cloneDeep, isNumber } from 'lodash';

// const likertsY = 216; // 277;

const Statements: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { elementId, id }: any = route.params || {};
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const [likerts, setLikerts] = useState({});
  const { saveLikerts, setLearnerStore, likerts: savedLikerts, likertElement } = Store.useLearnerStore((state) => ({
    setLearnerStore: state.set,
    saveLikerts: state.saveLikerts,
    likerts: state.likerts,
    likertElement: state.likertElement,
  }), shallow);
  const { pathfinder } = Store.usePathfinderStore(state => ({
    pathfinder: state.pathfinder,
  }), shallow);
  const { statements, getStatements, loading } = Store.useStatementStore((state) => ({
    statements: state.statements,
    getStatements: state.getStatements,
    loading: state.loading,
  }), shallow);
  const { width } = useWindowDimensions();
  const [likerLeftX, setLikerLeftX] = useState(0);
  const [likerRightX, setLikerRightX] = useState(0);
  const [likertsY, setLikertsY] = useState(0);
  const [likertLabelWidth, setLikertLabelWidth] = useState(120);
  const { learner } = Store.useLearnerStore();

  useEffect(() => {
    if (savedLikerts && Object.keys(savedLikerts).length && likertElement === elementId) {
      setLikerts(savedLikerts);
    }
  }, [savedLikerts])

  useEffect(() => {
    if (!elementId) {
      navigate(SCREEN.Home, { id });
      return;
    }
    if (elementId && pathfinder?.id) {
      getStatements(pathfinder.id, elementId)
    }
  }, [elementId, pathfinder])

  const selectLikertScale = (statementId, score) => {
    if (statementId === undefined) {
      return;
    }
    let _likerts = cloneDeep(likerts) || {};
    _likerts[statementId] = score;
    setLikerts(_likerts);
  }

  const STEPS = useMemo(() => [
    { name: pathfinder?.elementsTitle || 'Select role', onPress: () => navigate(SCREEN.Home) },
    { name: 'Describe your work' },
    { name: 'Get the plan' },
  ], [pathfinder, elementId])

  const showConfirmClose = () => {
    setTimeout(() => {
      modalConfirm.show({
        title: 'Exit',
        message: 'Thank you for completing your Pathfinder. It is now safe to close your browser window.', // 'Are you sure you want to exit?',
        onConfirm: async () => {
          try {
            window.parent.postMessage({
              event_id: 'close_scorm',
            }, "*");
          } catch (error) {
            console.log("closeScorm_err", error);
          }
          navigate(SCREEN.Home, { id })
        },
        cancelText: 'Cancel',
        confirmText: 'Confirm',
      }, {
        contentStyle: {
          maxWidth: 500,
        }
      })
    }, 300);
  }

  const onSubmit = () => {
    const _likerts = { ...likerts };
    statements.forEach(s => {
      if (s.id && !isNumber(_likerts[s.id])) {
        _likerts[s.id] = 1;
      }
    })
    setLearnerStore({ likerts: _likerts });
    saveLikerts();
    navigate(SCREEN.Learnings, {
      id: pathfinder?.id,
      elementId,
    });
    // setLikerts({});
    if (pathfinder?.completionInstruction) {
      const replaceData = {
        FirstName: learner?.firstName || '',
        PathfinderName: pathfinder.name || "Pathfinder",
      }
      modalConfirm.show({
        title: pathfinder?.data?.resultDialogTitle || 'Congratulations',
        message: VarHelper.replacePlaceholders(pathfinder.completionInstruction || '', replaceData),
        onConfirm: async () => { },
        onCancel: showConfirmClose,
        cancelText: 'Exit',
        confirmText: 'Go to results',
      }, {
        contentStyle: {
          maxWidth: 500,
        }
      })
    }
  }

  const onLayoutLikerts = useCallback((e: any) => {
    const { left, width, top } = e.nativeEvent.layout;
    const foo = width / 10 * 6;
    const labelWidth = width / 10 * 4 / 3;
    setLikerLeftX(left + foo + 48);
    setLikerRightX(Math.min(left + width - 48, left + width - (labelWidth / 2)));
    setLikertLabelWidth(labelWidth);
  }, [])

  const onLayoutTitle = useCallback((e: any) => {
    const { top } = e.nativeEvent.layout;
    setLikertsY(top + 43 - 76);
  }, []);

  const onPressHome = () => {
    navigate(SCREEN.Home, { id });
  }

  // const canSubmit = likerts && Object.keys(likerts).length > 0;

  return (
    <LearnerLayout>
      <MainContentContainer dataSet={{ element: 'statement-list' }}>
        <StepsHorizontal
          steps={STEPS}
          currentStep={1}
          marginTop={39}
          marginBottom={24}
        />
        <Col key={String(width)} bgWhite shadow padding={breakpoint !== 'xs' ? 40 : 16} borderRadius={8} onLayout={onLayoutLikerts}>
          <Text fontSize={breakpoint !== 'xs' ? 32 : 16} fontWeight={'bold'}>{pathfinder?.listStatementTitle}</Text>
          <Text fontSize={breakpoint !== 'xs' ? 20 : 14} mt1>{pathfinder?.listStatementSubtitle}</Text>
          <Col onLayout={onLayoutTitle} />
          {breakpoint !== "xs" ?
            <Col
              marginTop={43} marginBottom={12}
              position={"sticky"} top={0} zIndex={99}
            >
              <Col flex1 paddingBottom={8}>
                <Text fontSize={16} bold>Work Statements</Text>
              </Col>
            </Col>
            :
            <Col height={32} borderBottomWidth={1} borderColor={COLOR.DIVIDER} />
          }
          {loading ?
            <ActivityIndicator color={COLOR.MAIN} />
            :
            <>
              {statements?.map((i, idx) => {
                const value = likerts?.[i.id] === undefined ? 1 : likerts?.[i.id];
                return (
                  <StatementItem
                    key={`statement${idx}`}
                    hideBorder={idx === statements?.length - 1}
                    statement={i.statement}
                    value={value}
                    onChange={(val) => selectLikertScale(i.id, val)}
                    rolloverText={String(i[`rolloverLikert${value + 1}`])}
                    likertScaleTitles={[pathfinder?.likertScaleTitle1, pathfinder?.likertScaleTitle2, pathfinder?.likertScaleTitle3]}
                  />
                )
              })}
            </>
          }
        </Col>
        <Col height={100} />
      </MainContentContainer>
      <div className='sticky-footer'>
        <Col bgWhite>
          <MainContentContainer>
            <Col alignItems={breakpoint === 'xs' ? undefined : 'flex-end'}>
              {/* <Col marginBottom={breakpoint === 'xs' ? 24 : undefined} alignItems={breakpoint === 'xs' ? 'center' : 'flex-start'}>
                <Text
                  color={COLOR.MAIN} fontWeight={'500'}
                  fontSize={16} onPress={() => alert('to-do')}
                >
                  Need support? Get free consultant now!
                </Text>
              </Col> */}
              <Row>
                <Button
                  text='Reset'
                  backgroundColor={'transparent'}
                  bgHovered='transparent'
                  width={undefined}
                  paddingHorizontal={24}
                  marginRight={16}
                  onPress={() => setLikerts({})}
                  textProps={{ fontWeight: 'bold', fontSize: 16, color: COLOR.SUB_TEXT }}
                />
                <Button
                  text='Get my personal plan'
                  borderRadius={24}
                  height={48}
                  width={undefined}
                  flex={1}
                  paddingHorizontal={24}
                  // opacity={canSubmit ? 1 : 0.2}
                  // disabled={!canSubmit}
                  textProps={{ fontWeight: 'bold', fontSize: 16 }}
                  onPress={onSubmit}
                  dataSet={{ element: 'submit-answers' }}
                />
              </Row>
            </Col>
          </MainContentContainer>
          {breakpoint !== 'xs' &&
            <Col onPress={onPressHome} absolute left={24} bottom={16}>
              <Image source={ASSETS.LOGO} style={styles.logoContainer} resizeMode='contain' />
            </Col>
          }
        </Col>
      </div>
      {breakpoint !== 'xs' && (
        <>
          <Col pointerEvents="none" width={likertLabelWidth} alignItems="center" position="absolute" left={likerLeftX - likertLabelWidth / 2} top={likertsY}>
            <Text
              // marginLeft={Math.min(0, likerLeftWidth / 2 * -1 + 20)}
              fontSize={16} fontWeight={'500'} textAlign='center'
              numberOfLines={2}
            >
              {pathfinder?.likertScaleTitle1}
            </Text>
          </Col>
          <Col pointerEvents="none" width={likertLabelWidth} alignItems={'center'} position="absolute" left={(likerLeftX + likerRightX) / 2 - likertLabelWidth / 2} top={likertsY}>
            <Text
              fontSize={16} fontWeight={'500'}
              textAlign='center'
              numberOfLines={2}
            >
              {pathfinder?.likertScaleTitle2}
            </Text>
          </Col>
          <Col pointerEvents="none" width={likertLabelWidth} alignItems="center" position="absolute" left={likerRightX - likertLabelWidth / 2} top={likertsY}>
            <Text
              // marginRight={Math.min(0, likerRightWidth / 2 * -1 + 20)}
              fontSize={16} fontWeight={'500'} textAlign='center'
              numberOfLines={2}
            >
              {pathfinder?.likertScaleTitle3}
            </Text>
          </Col>
        </>
      )}
    </LearnerLayout>
  )
};

const styles = StyleSheet.create({
  logoContainer: {
    height: 44,
    width: 44 * 3.4,
  }
})

Statements.routeInfo = {
  title: 'Describe your work',
  path: '/:id/statements',
};

export default Statements;
