import React, { useMemo, useState } from 'react';
import SelectWeb, { components, Props as SelectProps } from 'react-select';
import { Col, IColProps } from 'components';
import { COLOR } from 'const';
import AsyncSelect, { useAsync } from 'react-select/async';
import { isArray, isString, uniqBy } from 'lodash';
import { VarHelper } from 'helpers';

interface ISelectProps extends IColProps {
  required?: boolean,
  value: any,
  onChange: any,
  options?: Array<any>,
  styles?: any,
  noBorder?: boolean,
  isMulti?: boolean,
  innerProps?: SelectProps,
  loadOptions?: any,
  defaultOptions?: Array<any>,
  [additionProp: string]: any,
}

export interface ISelectTagInputProps extends ISelectProps {
}

const Select = ({ value, onChange, options, noBorder, isMulti, innerProps, loadOptions, defaultOptions, ...props }: ISelectProps) => {
  const height = props.height ? { height: props.height, minHeight: props.height } : {};
  const SelectComponent = !!loadOptions ? AsyncSelect : SelectWeb;
  const handleInputChange = !loadOptions ? undefined : (newValue) => {
    props.onInputChange?.(newValue)
  };

  return (
    <Col {...props}>
      <SelectComponent
        value={value}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        styles={{
          control: (style) => ({
            ...style,
            height: isMulti ? 'auto' : 40,
            minHeight: 40,
            borderRadius: 8,
            backgroundColor: 'transparent',
            borderColor: noBorder ? 'transparent' : COLOR.BORDER,
            boxShadow: 'none',
            '&:hover': {
              borderColor: noBorder ? 'transparent' : COLOR.BORDER,
            },
            ...height,
          }),
          input: styles => ({ ...styles, outline: 'none', ...height, }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          placeholder: (style) => ({
            ...style,
            fontSize: 14,
            color: COLOR.FONT,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          ...props.styles,
        }}
        menuPortalTarget={document.querySelector('body')}
        {...innerProps}
      />
    </Col>
  )
}

export const Select01 = (props: ISelectProps) => {
  return (
    <Select
      noBorder
      backgroundColor={COLOR.GREY_LIGHT}
      borderRadius={4}
      {...props}
    />
  )
}

export const SelectTagInput = (props: ISelectTagInputProps) => {
  const { value, onChange, ...restProp } = props;
  const [inputValue, setInputValue] = useState('');

  const _value = useMemo(() => {
    if (!value) return [];
    if (isArray(value)) return value;
    if (!isString(value)) return [];
    return value.split(',').map(i => ({ label: i, value: i }));
  }, [value])

  const loadOptions = (inputValue, callback) => {
    if (inputValue.includes(',')) callback(restProp.options);
    else callback([
      { label: inputValue, value: inputValue },
      ...VarHelper.searchInArray(restProp.options, inputValue),
    ])
  }

  const onInputChange = (newValue: string) => {
    if (newValue?.length > 1 && newValue.includes(',')) {
      const newValues = _value;
      newValue.split(',').forEach(i => {
        if (i && !newValues.some(tag => tag.label === i.trim())) {
          newValues.push({
            label: i.trim(),
            value: i.trim(),
          });
        }
      });
      onChange(uniqBy(newValues, (i) => i.value));
      setInputValue('');
      return;
    }
    setInputValue(newValue);
  }

  return (
    <Select
      noBorder
      {...restProp}
      value={_value}
      styles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          visibility: 'hidden',
          width: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          visibility: 'hidden',
          width: 0,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          width: 0,
        }),
      }}
      loadOptions={loadOptions}
      onInputChange={onInputChange}
      onChange={onChange}
      innerProps={{
        inputValue,
        placeholder: '',
      }}
      defaultOptions={restProp.options}
    />
  )
}

export default Select;
