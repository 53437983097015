import { useNavigation, useRoute } from '@react-navigation/native';

export const reset = (navigation, screenName) => {
  navigation.reset({ index: 0, routes: [{ name: screenName }] });
};

export const goBackOrTo = (navigation, screenName) => {
  const { index } = navigation.dangerouslyGetState();
  index === 0 ? navigation.navigate(screenName) : navigation.goBack();
};

let _idDatas = {};
export const goWithPreData = (navigation, screenName, params, preData) => {
  if (preData.id) _idDatas[preData.id] = preData;
  navigation.navigate(screenName, params);
};
export const getScreenPreData = (id) => _idDatas[id];

export const useParams = () => {
  const params = useRoute().params || {};
  const { id, region, slug, ...restParams } = params as { id: string, region: string, slug: string };
  // dev: localhost:{port}/{slug}/{region}/{pathfinderId}
  // prod: {slug}.learner.harborpathfinder.com/{pathfinderId}
  return {
    ...(restParams || {}),
    id,
    region,
    slug: slug || window.location.host.split(".")[0],
  }
}

export const useNavFunc = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const currentParams = useParams();

  return {
    route,
    navigation,
    navigate: (screenName, params = {}) => navigation.navigate(screenName, {
      id: currentParams.id,
      region: currentParams.region,
      slug: currentParams.slug,
      ...params,
    }),
    goWithPreData: (screenName, params, preData) => goWithPreData(navigation, screenName, params, preData),
    getScreenPreData,
    reset: (screenName) => reset(navigation, screenName),
    goBackOrTo: (screenName) => goBackOrTo(navigation, screenName),
  };
};
