import create from 'zustand';
import Client, { TElement, TLearner } from './api-client';
import _, { cloneDeep } from 'lodash';
import Store from 'store';

interface ILearnerStore {
  learner: TLearner,
  selectedElement?: TElement,
  likerts: { [statementId: string]: number },
  token: string,
  asked: boolean,
  likertElement?: string,
  loading?: boolean,
  error?: Error,
  set: (data: any) => void,
  editLearner: (key: string, value: any) => void,
  saveLearner: (id: string) => void,
  restoreLearner: () => void,
  selectElement: (element: TElement) => void,
  saveLikerts: () => void,
}

const LEARNER_STORE_KEY = 'LEARNER_INFO';

export const useLearnerStore = create<ILearnerStore>((set, get) => ({
  learner: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  likerts: {},
  selectedElement: null,
  likertElement: null,
  loading: false,
  token: '',
  asked: false,
  set,
  restoreLearner: () => {
    try {
      const learnerStr = localStorage.getItem(LEARNER_STORE_KEY);
      if (!learnerStr) return;
      const { learner, selectedElement } = JSON.parse(learnerStr);
      set({ learner, selectedElement });
    } catch (error) { }
  },
  editLearner: (key, value) => {
    if (!key) return;
    set(state => {
      const learner = _.cloneDeep(state.learner);
      learner[key] = value;
      return {
        learner,
      }
    });
  },
  saveLearner: async (pathfinderId) => {
    try {
      if (get().loading) return;
      set({ loading: true, error: undefined });
      const learner = get().learner;
      const res = await Client.Api.Learner.contact({
        email: learner.email,
        firstName: learner.firstName,
        lastName: learner.lastName,
        pathfinderId,
      })
      if (res.data?.success && res.data?.data) {
        const { publicInfo, token, likerts } = res.data?.data || {};
        publicInfo.pathfinderId = pathfinderId;
        const formattedLikerts = {};
        Object.keys(likerts?.likerts || {}).forEach(statementId => {
          formattedLikerts[statementId] = likerts?.likerts?.[statementId]?.likertIdx;
        });

        set({
          learner: publicInfo,
          asked: true,
          likerts: formattedLikerts,
          likertElement: likerts?.elementId,
        });
        let saveData: any = {};
        try {
          saveData = JSON.parse(localStorage.getItem(LEARNER_STORE_KEY));
        } finally {
          saveData.learner = publicInfo;
          localStorage.setItem(LEARNER_STORE_KEY, JSON.stringify(saveData));
        }
        Store.Client.setLearnerToken(token);
      }
    } catch (error) {
      set({ error });
    } finally {
      set({ loading: false });
    }
  },
  selectElement: (selectedElement) => {
    set({ selectedElement })
  },
  saveLikerts: async () => {
    const { learner, likerts, selectedElement } = get();
    await Store.Api.Learner.submit({
      elementId: selectedElement?.id,
      likerts,
      pathfinderId: selectedElement?.pathfinderId,
    });
    set({ likertElement: selectedElement?.id });
    localStorage.setItem(LEARNER_STORE_KEY, JSON.stringify({
      learner,
      selectedElement,
    }));
  }
}));
