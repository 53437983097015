import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Row, Text } from "components/base";
import { IRowProps } from "components/base/Row";
import { COLOR } from "const";
import { useDynamicResponsiveValue } from "quickly-react";
import React from "react";

interface Props extends IRowProps {
  steps: { name: string, onPress?: () => void }[]
  currentStep: number
}

const StepHorizontals = (props: Props) => {
  const { steps, currentStep, ...restProps } = props;
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  return (
    <Row alignSelf={'center'} width100p={breakpoint === 'xs'} {...restProps}>
      {steps.map((i, idx) => {
        const leftLineColor = idx === 0 ? 'transparent' : (idx <= currentStep ? COLOR.SUCCESS : COLOR.DIVIDER);
        const rightLineColor = idx === steps.length - 1 ? 'transparent' : (idx < currentStep ? COLOR.SUCCESS : COLOR.DIVIDER);
        let iconBg = 'transparent';
        let iconBorder = 0;
        if (idx === currentStep) iconBg = COLOR.MAIN;
        if (idx < currentStep) iconBg = COLOR.SUCCESS;
        if (idx > currentStep) iconBorder = 2;

        return (
          <Col key={`step${idx}`} flex={breakpoint === 'xs' ? 1 : undefined}>
            <Row alignItems={'center'} marginBottom={9}>
              <Col flex1 backgroundColor={leftLineColor} height={2} />
              <Col
                width={17} height={17} borderRadius={'100%'} middle
                backgroundColor={iconBg} borderWidth={iconBorder} borderColor={COLOR.DIVIDER}
              >
                {idx < currentStep &&
                  <FontAwesome5 name='check' color={COLOR.WHITE} size={10} />
                }
              </Col>
              <Col flex1 backgroundColor={rightLineColor} height={2} />
            </Row>
            <Text
              onPress={i.onPress}
              fontSize={breakpoint === 'xs' ? 14 : 16}
              fontWeight='500' textAlign={'center'} alignSelf={'center'}
              marginHorizontal={breakpoint === 'xs' ? undefined : 32}>
              {i.name}
            </Text>
          </Col>
        )
      })}
    </Row>
  )
}

export default StepHorizontals;
