import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Image as ImageRN, ImageProps } from 'react-native';
import { QuickComponent, IStyleProps } from 'quickly-react';
import { addCommonStyle, TCommonPropStyle } from './addCommonStyle';
import Col from './Col';
import { Entypo } from '@expo/vector-icons';
import { COLOR } from 'const';

const quickComponent = new QuickComponent();
quickComponent.setupDefaultProps([], {
  shouldDetectStyleProps: true,
});
addCommonStyle(quickComponent);

export interface IImageProps extends ImageProps, Omit<IStyleProps, 'borderRadius'>, TCommonPropStyle {
  onRef?: any;
  autoSize?: boolean;
}

const ImageComponent = (props: IImageProps) => {
  const [err, setErr] = useState(false);
  const [ratio, setRatio] = useState(0);
  const onError = (e) => {
    setErr(true);
  };
  useEffect(() => {
    // @ts-ignore
    if (!props.source || (typeof props.source === 'object' && !props.source.uri)) {
      setErr(true);
    } else {
      setErr(false);
      // @ts-ignore
      const uri = props.source?.uri;
      if (uri) {
        ImageRN.getSize(uri, (width, height) => {
          setRatio(width / height);
        });
      }
    }
  }, [props.source]);

  const style = useMemo(() => [
    props.style,
    props.autoSize && ratio && {
      aspectRatio: ratio
    }
  ], [ratio, props.autoSize, props.style]);

  if (err) {
    return (
      <Col overflow={'hidden'} {...props}>
        <Col absoluteFill backgroundColor={COLOR.MAIN} opacity={0.2} />
        <Col absoluteFill middle>
          <Entypo name="image" size={24} color="rgba(0,0,0,0.3)" />
        </Col>
      </Col>
    )
  }

  return <ImageRN ref={props.onRef} onError={onError} {...props} style={style} />;
};

const Image: React.FC<IImageProps> = quickComponent.make(ImageComponent);

export default Image;
