import React from 'react';
import { Text, IColProps, Row, Col } from 'components';
import { COLOR } from 'const';
import TouchField from './TouchField';

interface IRadioProps extends IColProps {
  text: string;
  selected?: boolean;
  textProps?: any;
}

const Radio = (props: IRadioProps) => {
  const { text, selected, textProps, ...restProps } = props;
  return (
    <Col {...restProps}>
      <Row>
        <Col
          width={22} height={22} borderRadius={11}
          borderColor={COLOR.DIVIDER} borderWidth={1} middle
        >
          {selected &&
            <Col width={16} height={16} borderRadius={8} backgroundColor={COLOR.MAIN} />
          }
        </Col>
        <Text fontWeight={500} color={COLOR.MAIN_TEXT} marginLeft={12} {...textProps}>
          {text}
        </Text>
      </Row>
    </Col>
  );
};

export default Radio;
