import create from 'zustand';
import { TClient, TPathfinder } from 'type';
import Store from 'store';
import _ from 'lodash';

interface IPathfinderStore {
  pathfinder?: TPathfinder,
  client?: TClient,
  loading: boolean,
  errors: Error,
  set: (data: any) => void,
  getPathfinderDetail: (id: string) => void,
}

export const usePathfinderStore = create<IPathfinderStore>((set, get) => ({
  pathfinder: null,
  client: null,
  loading: false,
  errors: null,
  set,
  getPathfinderDetail: async (id: string) => {
    const res = await Store.Api.Pathfinder.detail({
      id,
    })
    const res2 = await Store.Api.Client.detail({
      id: (res.data.data as TPathfinder).clientId,
    })
    if (res?.data?.success && res?.data?.data) {
      set({
        pathfinder: res.data.data,
        client: res2.data.data,
      })
    }
  }
}));
