import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, TouchField, SwapIconEffect, ContactForm, MainContentContainer, Text, Image } from 'components';
import { COLOR, SCREEN } from 'const';
import { ASSETS } from 'assets';
import { useDynamicResponsiveValue } from 'quickly-react';
import * as Animatable from 'react-native-animatable';
import { Animated, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavFunc, useParams } from 'navigation';
import { useExpansionAnim, animStill } from './CMSLayout.anim';
import Store from 'store';
import shallow from 'zustand/shallow';
import Drawer from './Drawer';
import { isEmpty } from 'lodash';
import { VarHelper } from 'helpers';

export const HEADER_LOGO_HEIGHT = 76;
export const LOGO_MAX_WIDTH = 198;

const MainContent = Animated.View;

let didAnimatedOneTime = false;

interface ILearnerLayoutProps {
  children: any,
  requireAuthen?: boolean,
  loginRedirectScreen?: string,
  expanded?: boolean,
  isHome?: boolean,
}

const CMSMainContent = ({ children }: any) => {
  return (
    <Animatable.View animation={!didAnimatedOneTime ? 'fadeIn' : animStill} duration={300} delay={300} style={{ flex: 1 }}>
      {children}
    </Animatable.View>
  )
}

const LearnerLayout = ({ children, expanded, isHome }: ILearnerLayoutProps) => {
  const { navigate } = useNavFunc();
  const params = useParams() as { id: string, region: string, slug: string };
  const [mobileLeftIconIndex, setMobileLeftIconIndex] = useState(0);
  const { shouldDoExpansionAnim, headerAnimated, mainAnimated } = useExpansionAnim(expanded);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const { selectedElement, learner, asked, likerts } = Store.useLearnerStore(state => ({
    learner: state.learner,
    asked: state.asked,
    selectedElement: state.selectedElement,
    likerts: state.likerts,
  }), shallow);
  const { pathfinder, getPathfinderDetail, client } = Store.usePathfinderStore(state => ({
    pathfinder: state.pathfinder,
    getPathfinderDetail: state.getPathfinderDetail,
    client: state.client,
  }), shallow);
  const { statements, getStatements } = Store.useStatementStore((state) => ({
    statements: state.statements,
    getStatements: state.getStatements,
  }), shallow);
  const { getSiteInfo } = Store.useMainStore();

  const slug = params?.slug;
  useEffect(() => {
    if (window.location.host.includes("pathfinder-rebuild.devserver.london")) {
      getPathfinderDetail(params?.id);
    } else {
      if (slug) {
        getSiteInfo(slug).then(info => {
          if (!info) return;
          const correctUrl = VarHelper.replacePlaceholders(info.url, {
            pathfinderId: params?.id,
          });
          if (!VarHelper.isSameDomainAndPath(correctUrl, window.location.href)) {
            window.location.href = correctUrl;
            return;
          }
          getPathfinderDetail(params?.id);
        });
      }
    }
  }, [slug])

  const openMobileDrawer = () => {
    setMobileLeftIconIndex(1);
  };

  const closeMobileDrawer = () => {
    setMobileLeftIconIndex(0);
  };

  const onPressHome = () => {
    navigate(SCREEN.Home, { id: params?.id })
  }

  const onPressLearningPlan = useCallback(() => {
    if (selectedElement?.id && pathfinder?.id && !statements.length) {
      getStatements(pathfinder.id, selectedElement.id);
    }
    navigate(SCREEN.Learnings, {
      id: pathfinder?.id,
      elementId: selectedElement?.id,
    })
  }, [selectedElement, pathfinder, statements])

  const mainContent = useMemo(() => {
    if (!pathfinder?.id) return null;
    if (asked) return children;
    return (
      <ContactForm
        pathfinderId={params?.id}
      />
    );
  }, [children, pathfinder, asked, params])

  const showMenu = Boolean(asked && !!selectedElement?.id);

  const renderHeader = () => {
    return (
      <>
        <Animated.View
          // @ts-ignore
          style={{
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'stretch',
            height: HEADER_LOGO_HEIGHT,
            transform: [
              { translateY: headerAnimated.containerY },
            ],
            shadowColor: "#000",
            shadowOffset: {
              width: 1,
              height: 2,
            },
            shadowOpacity: 0.12,
            shadowRadius: 6.46,
            elevation: 9,
          }}
        >
          {breakpoint === 'xs' ? (
            <Row
              width100p justifyContent={'space-between'}
              paddingLeft={16} alignItems={'center'} height={HEADER_LOGO_HEIGHT}
            >
              <Col onPress={onPressHome}>
                {pathfinder?.logo
                  ? <Image source={{ uri: pathfinder?.logo }} style={{ width: 137, height: 40 }} resizeMode='contain' />
                  : <Text fontSize={18} fontWeight='600'>{pathfinder?.name}</Text>
                }
              </Col>
              {showMenu &&
                <SwapIconEffect
                  width={50} height={55}
                  displayIndex={mobileLeftIconIndex}
                  effect={mobileLeftIconIndex === 0 ? 'rotate' : 'rotate-backward'}
                >
                  <TouchField width={50} height={55} middle onPress={openMobileDrawer}>
                    <Ionicons name="menu-sharp" size={24} color={COLOR.MAIN} />
                  </TouchField>
                  <TouchField width={50} height={55} middle onPress={closeMobileDrawer}>
                    <Ionicons name="close" size={24} color={COLOR.MAIN} />
                  </TouchField>
                </SwapIconEffect>
              }
            </Row>
          ) : (
            <Row flex={1} paddingHorizontal={12}>
              <Col onPress={onPressHome} height={HEADER_LOGO_HEIGHT}>
                <Image source={{ uri: client?.logo }} autoSize style={styles.logoContainer} resizeMode='contain' />
              </Col>
              <Row flex1 alignItems={'center'} justifyContent={'center'}>
                {showMenu &&
                  <MainContentContainer>
                    <Row>
                      <TouchField onPress={onPressHome}>
                        <Text color={isHome ? COLOR.MAIN : COLOR.SUB_TEXT} fontSize={16} fontWeight={'500'}>Home</Text>
                      </TouchField>
                      {!isEmpty(likerts) &&
                        <TouchField nativeID='menu-learning-plan' onPress={onPressLearningPlan} marginLeft={40}>
                          <Text color={!isHome ? COLOR.MAIN : COLOR.SUB_TEXT} fontSize={16} fontWeight={'500'}>Personal Learning Plan</Text>
                        </TouchField>
                      }
                    </Row>
                  </MainContentContainer>
                }
              </Row>
              {pathfinder?.logo ?
                <Col
                  onPress={onPressHome}
                  height={HEADER_LOGO_HEIGHT}
                >
                  <Image source={{ uri: pathfinder?.logo }} autoSize style={styles.logoContainer} resizeMode='contain' />
                </Col>
                :
                <Text fontSize={20} fontWeight='600' marginRight={12}>{pathfinder?.name}</Text>
              }
              {pathfinder?.data?.showPathfinderName && pathfinder?.logo &&
                <Text fontSize={20} fontWeight='600' marginHorizontal={12}>{pathfinder?.name}</Text>
              }
            </Row>
          )}
        </Animated.View>
      </>
    );
  };

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      {renderHeader()}
      <Col stretch flex1>
        <MainContent
          style={shouldDoExpansionAnim ? {
            position: 'absolute',
            left: mainAnimated.left,
            top: mainAnimated.top,
            right: 0,
            bottom: 0,
          } : {
            flex: 1,
            height: '100%',
          }}
        >
          <CMSMainContent>
            {mainContent}
          </CMSMainContent>
        </MainContent>
      </Col>
      {showMenu && breakpoint === 'xs' &&
        <Drawer
          isHome={isHome}
          navigate={navigate}
          onPressHome={onPressHome}
          onPressLearningPlan={onPressLearningPlan}
          show={mobileLeftIconIndex === 1}
          likerts={likerts}
        />
      }
    </Col>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    height: HEADER_LOGO_HEIGHT - 12 * 2,
    margin: 12,
    maxWidth: LOGO_MAX_WIDTH,
  }
})

export default LearnerLayout;
