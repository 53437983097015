import { Text, LearnerLayout, Row, Input, Button, MainContentContainer, Grid, ElementItem, SelectFilter, Col, Image } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IScreen, TElement } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import shallow from 'zustand/shallow';
import { FontAwesome5 } from '@expo/vector-icons';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { VarHelper } from 'helpers';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ASSETS } from 'assets';

const Home: IScreen = () => {
  const { navigate } = useNavFunc();
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const { learner, selectElement } = Store.useLearnerStore((state) => ({
    learner: state.learner,
    selectElement: state.selectElement,
  }), shallow);
  const { elements, getElements, filterKeys, loading } = Store.useElementStore(state => ({
    elements: state.elements,
    getElements: state.getElements,
    filterKeys: state.filterKeys,
    loading: state.loading,
  }));
  const { pathfinder } = Store.usePathfinderStore(state => ({
    pathfinder: state.pathfinder,
  }), shallow);

  const [searchKey, setSearchKey] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const pathfinderId = pathfinder?.id;

  useEffect(() => {
    if (pathfinderId) {
      getElements(pathfinderId);
    }
  }, [pathfinderId])

  const onPressElement = useCallback((element: TElement) => {
    selectElement(element);
    navigate(SCREEN.Statements, {
      id: pathfinderId,
      elementId: element.id,
    });
  }, [pathfinderId])

  const renderElements = useMemo(() => {
    if (loading) {
      return (
        <ActivityIndicator color={COLOR.MAIN} />
      )
    }
    let list = [...elements];
    if (searchKey) {
      list = list.filter(i =>
        i.name.toLowerCase().includes(searchKey.toLowerCase())
      )
    }
    if (selectedKeys?.length) {
      list = list.filter(i =>
        i.filterKeys && selectedKeys.some((key) => {
          return VarHelper.parseKeysToArr(i.filterKeys).includes(key.toLowerCase())
        })
      )
    }
    return (
      <Grid
        xs="50%" sm="33.33%" lg='16.66%' marginRight={-24}
        alignItems={"flex-start"}
        justifyContent={elements?.length === 1 ? "center" : undefined}
      >
        {list?.map(i => (
          <ElementItem
            key={i.id}
            name={i.name}
            rolloverText={i.rolloverText}
            image={i.image}
            onPress={() => onPressElement(i)}
            hideName={elements?.length === 1}
          />
        ))}
      </Grid>
    )
  }, [elements, selectedKeys, elements, loading, searchKey, onPressElement])

  const shouldShowFilter = !pathfinder?.disableElementsFilter && elements?.length > 1 && filterKeys?.length > 0;

  return (
    <LearnerLayout isHome>
      <MainContentContainer>
        <Text fontSize={32} bold>Hi {learner?.firstName},</Text>
        <Text fontSize={20} mt1>
          {VarHelper.replacePlaceholders(pathfinder?.welcomeInstruction || '', {
            FirstName: learner?.firstName || '',
            PathfinderName: pathfinder?.name || "Pathfinder",
          })}
        </Text>
        <Text fontSize={20} mt2 color={COLOR.SUB_TEXT}>
          {VarHelper.replacePlaceholders(pathfinder?.additionalInstruction || '', {
            FirstName: learner?.firstName || '',
            PathfinderName: pathfinder?.name || "Pathfinder",
          })}
        </Text>
        <Row width100p mt3 mb2 alignItems="flex-start">
          {/* <Input
            flex1
            height={55}
            borderRadius={8}
            borderColor={'#CDD4D4'}
            value={searchKey}
            onChange={setSearchKey}
            inputProps={{
              style: {
                // @ts-ignore
                outline: 'none',
                fontSize: 16,
                paddingLeft: 24,
              },
              placeholderTextColor: COLOR.SUB_TEXT,
            }}
            bgWhite
            placeholder='Search the role...'
          /> */}
          {shouldShowFilter &&
            <Button
              iconLeft={
                <FontAwesome5
                  name='filter' size={16}
                  color={showFilter ? COLOR.WHITE : COLOR.MAIN_TEXT}
                />
              }
              text='Filter'
              height={44}
              width={100}
              borderRadius={8}
              bgHovered={showFilter ? COLOR.MAIN : COLOR.GREY2}
              mainColor={showFilter ? COLOR.MAIN : COLOR.GREY2}
              textProps={{
                color: showFilter ? COLOR.WHITE : COLOR.MAIN_TEXT,
                fontSize: 16,
              }}
              dataSet={{ element: 'filter-button' }}
              onPress={() => setShowFilter(s => !s)}
            />
          }
          <Col flex1 marginLeft={12}>
            {shouldShowFilter &&
              <SelectFilter
                selectedKeys={selectedKeys}
                filterKeys={filterKeys}
                onChange={setSelectedKeys}
                visibile={showFilter}
              />
            }
          </Col>
        </Row>

        {renderElements}
      </MainContentContainer>
      <Col height={30} />
      {breakpoint !== 'xs' &&
        <Col absolute left={24} bottom={16}>
          <Image source={ASSETS.LOGO} style={styles.logoContainer} resizeMode='contain' />
        </Col>
      }
    </LearnerLayout>
  )
};

const styles = StyleSheet.create({
  logoContainer: {
    height: 44,
    width: 44 * 3.4,
  }
})

Home.routeInfo = {
  title: 'Home',
  path: '/:id',
};

export default Home;
