import { Text, LearnerLayout, Row, Button, MainContentContainer, StepsHorizontal, Col, Table, Image, TouchField, modalConfirm } from 'components';
import React, { useEffect, useMemo } from 'react';
import { IScreen, TLearning } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import shallow from 'zustand/shallow';
import { isArray, isNumber } from 'lodash';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ASSETS } from 'assets';
import { StyleSheet } from 'react-native';
import { VarHelper } from 'helpers';

const Learnings: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { elementId, id }: any = route.params || {};
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const { learner, selectedElement, likerts } = Store.useLearnerStore((state) => ({
    learner: state.learner,
    selectedElement: state.selectedElement,
    likerts: state.likerts,
  }), shallow);
  const { statements } = Store.useStatementStore((state) => ({
    statements: state.statements,
  }), shallow);
  const { pathfinder } = Store.usePathfinderStore(state => ({
    pathfinder: state.pathfinder,
  }), shallow);

  const onPressHome = () => {
    modalConfirm.show({
      title: 'Exit',
      message: 'Thank you for completing your Pathfinder. It is now safe to close your browser window.', // 'Are you sure you want to exit?',
      onConfirm: async () => {
        try {
          window.parent.postMessage({
            event_id: 'close_scorm',
          }, "*");
        } catch (error) {
          console.log("closeScorm_err", error);
        }
        navigate(SCREEN.Home, { id });
      },
      cancelText: 'Cancel',
      confirmText: 'Confirm',
    }, {
      contentStyle: {
        maxWidth: 500,
      }
    })
  }

  const STEPS = useMemo(() => [
    { name: pathfinder?.elementsTitle || 'Select role', onPress: () => navigate(SCREEN.Home) },
    {
      name: 'Describe your work', onPress: elementId && pathfinder?.id ? () => {
        navigate(SCREEN.Statements, {
          id: pathfinder?.id,
          elementId: elementId,
        });
      } : undefined,
    },
    { name: 'Get the plan' },
  ], [pathfinder, elementId])

  useEffect(() => {
    if (!likerts || !Object.keys(likerts).length) {
      navigate(SCREEN.Statements, { id });
      return;
    }
    try {
      window.parent.postMessage({
        event_id: 'update_lesson_status',
        status: 'completed',
      }, "*");
      if (window.parent?.location) {
        const isInIframe = window.location !== window.parent.location;
        if (!isInIframe) return;
        const eventName = "complete";
        console.log('send to ttc', eventName);
        window.parent.postMessage({ eventName }, "*");
      }
    } catch (error) {
    }
  }, [])

  const learnings = useMemo(() => {
    if (!statements?.length) return [];
    let result: TLearning[] = [];
    statements.forEach((statement) => {
      const _likertIdx = isNumber(likerts[statement.id]) ? likerts[statement.id] : 1;
      const statementLearnings = statement[`learningsLikert${_likertIdx + 1}`];
      if (isArray(statementLearnings)) {
        result = result.concat(statementLearnings);
      }
    })
    return result?.filter(Boolean)?.map(i => ({
      type: <Image source={{ uri: i.icon }} style={{ width: 70, height: 70 }} resizeMode='contain' />,
      name: i.name,
      url: <Text fontSize={16} onPress={() => window.open(i.url, "_blank")} color={COLOR.MAIN} fontWeight={'500'}>{i.urlShortName || i.url}</Text>,
      comment: i.comment,
    }))
  }, [statements, likerts])

  return (
    <LearnerLayout>
      <MainContentContainer dataSet={{ element: 'statement-list' }}>
        <StepsHorizontal
          steps={STEPS}
          currentStep={3}
          marginTop={39}
          marginBottom={24}
        />
        <Col bgWhite shadow padding={40} borderRadius={8}>
          <Text fontSize={32} bold>
            {VarHelper.replacePlaceholders(pathfinder?.listLearningTitle, {
              ElementName: selectedElement?.name,
              FirstName: learner?.firstName,
              PathfinderName: pathfinder?.name,
            })}
          </Text>
          <Text fontSize={20} mt1 marginBottom={33}>{learnings?.length} item{learnings?.length > 1 ? 's' : ''}</Text>
          <Table
            columns={[
              { key: 'type', title: 'Type', width: 95, padding: 10 },
              { key: 'name', title: 'Learning Name', flex: 1 },
              { key: 'url', title: 'URL', flex: 1 },
              { key: 'comment', title: 'Comment', flex: 1 },
            ]}
            data={learnings}
            style={{ margin: 20 }}
            minWidthRequired={700}
          />
        </Col>
        <Col height={100} />
      </MainContentContainer>
      <div className='sticky-footer'>
        <Col bgWhite>
          <MainContentContainer>
            <Row alignItems={'center'} justifyContent={'flex-end'}>
              {/* <Button
                nativeID='back-home'
                text='Back to Home'
                backgroundColor={'transparent'}
                bgHovered='transparent'
                width={undefined}
                paddingHorizontal={24}
                marginRight={16}
                onPress={onPressHome}
                textProps={{ fontWeight: 'bold', fontSize: 16, color: COLOR.MAIN }}
              /> */}
              {/* <Button
                text='Free Consultant'
                borderRadius={24}
                height={48}
                width={undefined}
                paddingHorizontal={24}
                textProps={{ fontWeight: 'bold', fontSize: 16 }}
              /> */}
              <Row alignItems={'center'}>
                <Col flex1 alignItems="flex-end" ml2>
                  <Text fontSize={14}>Thank you for completing your Pathfinder.{'\n'}It is now safe to close your browser window.</Text>
                </Col>
                <TouchField
                  height={40}
                  width={80}
                  middle
                  borderColor={COLOR.MAIN}
                  borderRadius={20}
                  borderWidth={1}
                  onPress={onPressHome}
                  ph2
                  ml2
                >
                  <Text color={COLOR.MAIN}>Exit</Text>
                </TouchField>
              </Row>
              <div style={{ height: 48 }} />
            </Row>
          </MainContentContainer>
          {breakpoint !== 'xs' &&
            <Col onPress={onPressHome} absolute left={24} bottom={16}>
              <Image source={ASSETS.LOGO} style={styles.logoContainer} resizeMode='contain' />
            </Col>
          }
        </Col>
      </div>
    </LearnerLayout>
  )
};

const styles = StyleSheet.create({
  logoContainer: {
    height: 44,
    width: 44 * 3.4,
  }
})

Learnings.routeInfo = {
  title: 'Personal Learning Plan',
  path: '/:id/learnings',
};

export default Learnings;
