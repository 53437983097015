import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, TouchField, Input, Text, SwapIconEffect, modal, UseMemo } from 'components';
import { COLOR, SCREEN } from 'const';
import { SVG, ASSETS } from 'assets';
import { useDynamicResponsiveValue } from 'quickly-react';
import * as Animatable from 'react-native-animatable';
import { StyleSheet, ActivityIndicator, Animated, Image } from 'react-native';
import { MaterialCommunityIcons, Feather, Ionicons } from '@expo/vector-icons';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { adminNav, clientNav } from './nav';
import SideNav from './SideNav';
import Drawer from './Drawer';
import { useExpansionAnim, animStill, AnimatableView } from './CMSLayout.anim';
import { Tooltip } from 'react-tippy';

export const HEADER_LOGIN_WIDTH = 269 * 0.7;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const HeaderBar = Row;
const SideBar = Col;
const MainContent = Animated.View;

let didAnimatedOneTime = false;

interface ICMSLayoutProps {
  children: any,
  requireAuthen?: boolean,
  loginRedirectScreen?: string,
  expanded?: boolean,
}

const CMSMainContent = ({ children }: any) => {
  return (
    <Animatable.View animation={!didAnimatedOneTime ? 'fadeIn' : animStill} duration={300} delay={300} style={{ flex: 1 }}>
      {children}
    </Animatable.View>
  )
}

const CMSLayout = ({ children, requireAuthen, loginRedirectScreen, expanded }: ICMSLayoutProps) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const [mobileLeftIconIndex, setMobileLeftIconIndex] = useState(0);

  const { shouldDoExpansionAnim, headerAnimated, mainAnimated } = useExpansionAnim(expanded);

  useEffect(() => {
    if (requireAuthen) {
      UserStore.onReady().then((u) => {
        if (!u) {
          const params = loginRedirectScreen ? { redirect: loginRedirectScreen } : undefined;
          navigate(SCREEN.Login, params);
        }
      });
    }
  }, [requireAuthen, loginRedirectScreen]);

  const onPressLogout = () => {
    Store.Api.User.logout();
    UserStore.set({ user: null, token: '' });
    navigate(SCREEN.Login);
  };

  const renderSidebarContainer = () => {
    if (breakpoint === 'xs') {
      return null;
    }
    if (shouldDoExpansionAnim || !didAnimatedOneTime) {

      const animation = !didAnimatedOneTime ? 'fadeIn' : shouldDoExpansionAnim ?
        (expanded ? "fadeOutLeftBig" : "fadeInLeftBig") : undefined;

      return (
        <AnimatableView
          firstRenderAnim={!didAnimatedOneTime ? 'fadeIn' : animStill}
          style={styles.sidebarDesktop}
          animation={animation}
          duration={300}
          onAnimationEnd={() => {
            didAnimatedOneTime = true;
          }}
        >
          {renderSidebar()}
        </AnimatableView>
      )
    }
    return (
      <SideBar style={styles.sidebarDesktop}>
        {renderSidebar()}
      </SideBar>
    );
  };

  const renderSidebar = () => {
    const isClient = UserStore.user?.role === 'client';
    const nav = isClient ? clientNav : adminNav;

    return (
      <Col nativeID={'sidebar'}>
        {nav.map((val, i) => (
          <SideNav key={val.title} title={val.title} screen={val.screen} icon={val.icon} />
        ))}
      </Col>
    );
  };

  const openMobileDrawer = () => {
    setMobileLeftIconIndex(1);
  };

  const closeMobileDrawer = () => {
    setMobileLeftIconIndex(0);
  };

  const renderHeader = () => {
    const userName = UserStore.user?.firstName ? `${UserStore.user?.firstName} ${UserStore.user?.lastName || ''}` : '';
    const userInitial = (() => {
      if (!userName) return '';
      const parts = userName.split(' ');
      if (parts.length === 1) return parts[0].slice(0, 2).toUpperCase();
      return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
    })();
    return (
      <>
        {/* <Col height={HEADER_LOGIN_HEIGHT} /> */}
        <Animated.View
          // @ts-ignore
          style={{
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'stretch',
            height: HEADER_LOGIN_HEIGHT,
            transform: [
              { translateY: headerAnimated.containerY },
            ],
            shadowColor: "#000",
            shadowOffset: {
              width: 1,
              height: 2,
            },
            shadowOpacity: 0.12,
            shadowRadius: 6.46,
            elevation: 9,
            // position: 'fixed',
            // top: 0,
            // left: 0,
            // right: 0,
            // zIndex: 100,
          }}
        >
          {breakpoint === 'xs' ? (
            <Row stretch width100p height={HEADER_LOGIN_HEIGHT}>
              <SwapIconEffect
                width={50} height={55}
                displayIndex={mobileLeftIconIndex}
                effect={mobileLeftIconIndex === 0 ? 'rotate' : 'rotate-backward'}
              >
                <TouchField width={50} height={55} middle onPress={openMobileDrawer}>
                  <Ionicons name="menu-sharp" size={24} color={COLOR.MAIN} />
                </TouchField>
                <TouchField width={50} height={55} middle onPress={closeMobileDrawer}>
                  <Ionicons name="close" size={24} color={COLOR.MAIN} />
                </TouchField>
              </SwapIconEffect>
              <Col flex1 middle>
                {/* <Text>Pathfinder</Text> */}
                <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
              </Col>
              <Tooltip trigger="mouseenter" title={"Logout"}>
                <TouchField dataSet={{ element: 'logout-icon' }} width={50} mr2 middle onPress={onPressLogout}>
                  <MaterialCommunityIcons name="logout" size={24} color={COLOR.MAIN} />
                </TouchField>
              </Tooltip>
            </Row>
          ) : (
            <>
              <Animated.View
                style={{
                  transform: [
                    { translateX: headerAnimated.logoXY.x },
                    { translateY: headerAnimated.logoXY.y },
                  ]
                }}
              >
                <Col
                  onPress={() => navigate(SCREEN.Home)}
                  middle width={HEADER_LOGIN_WIDTH}
                  height={HEADER_LOGIN_HEIGHT}
                >
                  {/* <SVG.BottleGoose
                    fill='white'
                    width={'56.25%'}
                  /> */}
                  <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
                </Col>
              </Animated.View>

              <HeaderBar flex1 justifyContent={'space-between'}>
                <Row flex1>
                  {/* <Input
                    height={30}
                    borderColor='transparent'
                    inputProps={{
                      style: {
                        // @ts-ignore
                        outline: 'none',
                        fontSize: 12,
                      },
                      placeholderTextColor: COLOR.GREY,
                    }}
                    placeholder='Search'
                  />
                  <Col absolute top={3} right={10}>
                    <Feather name="search" size={20} color={COLOR.GREY} />
                  </Col> */}
                </Row>
                <Row mr1>
                  {userInitial && (
                    <Col width={40} height={40} borderRadius={20} middle bgWhite mr1 borderWidth={1} borderColor={COLOR.MAIN}>
                      <Col middle backgroundColor={COLOR.MAIN} width={34} height={34} borderRadius={17}>
                        <Text colorWhite>{userInitial}</Text>
                      </Col>
                    </Col>
                  )}
                  <Text body2 mr0>{userName}</Text>
                  <Tooltip trigger="mouseenter" title={"Logout"}>
                    <TouchField dataSet={{ element: 'logout-icon' }} cirle width={40} height={40} middle onPress={onPressLogout}>
                      <MaterialCommunityIcons name="logout" size={24} color={COLOR.GREY} />
                    </TouchField>
                  </Tooltip>
                </Row>

              </HeaderBar>
            </>
          )}

        </Animated.View>
      </>
    );
  };

  return (requireAuthen && !UserStore.user) ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      {renderHeader()}
      <Row stretch flex1>
        {renderSidebarContainer()}
        <MainContent
          style={shouldDoExpansionAnim ? {
            position: 'absolute',
            left: mainAnimated.left,
            top: mainAnimated.top,
            right: 0,
            bottom: 0,
          } : {
            flex: 1,
            height: '100%',
          }}
        >
          <CMSMainContent>
            {children}
          </CMSMainContent>
        </MainContent>
      </Row>
      {/* {(mobileLeftIconIndex === 1) && (
        
      )} */}
      <Drawer
        navigate={navigate}
        show={mobileLeftIconIndex === 1}
      />
    </Col>
  );
};

const styles = StyleSheet.create({
  sidebarDesktop: {
    width: HEADER_LOGIN_WIDTH,
    height: '100%',
    backgroundColor: 'white',
    paddingTop: 60,
    // @ts-ignore
    overflowY: 'auto',
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 10,
    },
    shadowOpacity: 0.12,
    shadowRadius: 6.46,
    elevation: 9,
  }
})

export default CMSLayout;
