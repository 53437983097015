import React, { useState, useEffect } from 'react';
import * as Animatable from 'react-native-animatable';
import {
  useWindowDimensions,
} from 'react-native';

import { HEADER_LOGIN_HEIGHT } from './CMSLayout';
import { COLOR } from 'const';
import { TouchField } from 'components/elements';
import { Text } from 'components/base';
import { isEmpty } from 'lodash';

const Drawer = ({ likerts, show, isHome, onPressHome, onPressLearningPlan }: any) => {
  const [removed, setRemoved] = useState(true);
  useEffect(() => {
    if (show) {
      setRemoved(false);
    } else {
      setTimeout(() => {
        setRemoved(true);
      }, 500);
    }
  }, [show]);
  return removed ? null : (
    <Animatable.View
      animation={show ? 'fadeInRight' : 'fadeOutRight'}
      duration={500}
      style={{
        // height: height - HEADER_LOGIN_HEIGHT,
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        left: 0,
        right: 0,
        top: HEADER_LOGIN_HEIGHT,
        padding: 12,
        paddingBottom: 40,
      }}
    >
      <TouchField padding={12} onPress={onPressHome}>
        <Text color={isHome ? COLOR.MAIN : COLOR.SUB_TEXT} fontSize={16} fontWeight={'500'}>Home</Text>
      </TouchField>
      {!isEmpty(likerts) &&
        <TouchField padding={12} onPress={onPressLearningPlan}>
          <Text color={!isHome ? COLOR.MAIN : COLOR.SUB_TEXT} fontSize={15}>Personal Learning Plan</Text>
        </TouchField>
      }
    </Animatable.View>
  )
};

export default Drawer;