import { Col, Text, CMSLayout } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';

const CloneMe : IScreen = ( ) => {
  const { navigate } = useNavFunc();

  return  (
    <Col flex1 m2 p2 round1 bgWhite shadow>
      <Text h4>CloneMe</Text>
    </Col>
  );
};

CloneMe.routeInfo = {
  title: 'Pathfinder - Clone Me',
  path: '/clone-me',
};

export default CloneMe;