import { Col, Text, Button, Input } from 'components';
import React, { useEffect } from 'react';
import { COLOR } from 'const';
import { ASSETS } from 'assets';
import Store from 'store';
import { Animated } from 'react-native';
import shallow from 'zustand/shallow';

interface IContactFormProps {
  onLoginSuccess?: any,
  pathfinderId?: string,
}

const FIELDS = [
  { id: 'firstName', label: 'First Name', placeholder: 'Input first name' },
  { id: 'lastName', label: 'Last Name', placeholder: 'Input last name' },
  { id: 'email', label: 'Email', placeholder: 'Input your email' },
]

const ContactForm = ({
  onLoginSuccess,
  pathfinderId,
}: IContactFormProps) => {
  const { pathfinder } = Store.usePathfinderStore(state => ({
    pathfinder: state.pathfinder
  }), shallow);
  const { loading, learner, editLearner, saveLearner, error, restoreLearner } = Store.useLearnerStore(state => ({
    token: state.token,
    learner: state.learner,
    editLearner: state.editLearner,
    saveLearner: state.saveLearner,
    restoreLearner: state.restoreLearner,
    loading: state.loading,
    error: state.error,
  }), shallow);

  useEffect(() => {
    restoreLearner();
  }, [])

  const handleLogin = async () => {
    await saveLearner(pathfinderId);
    onLoginSuccess?.();
  };

  return (
    <Col flex1 backgroundColor={COLOR.GREY_BG} dataSet={{ element: 'contact-form' }}>
      <Col absoluteFill zIndex={1}>
        <Animated.Image source={ASSETS.FORM_BG} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
      </Col>
      <Col zIndex={2} flex1 middle>
        <Col backgroundColor='white' p3 borderRadius={8}>
          <Text fontSize={20} fontWeight='600'>Welcome to {pathfinder?.name} Pathfinder</Text>
          <Text mt1>Enter your details to create your personal learning plan</Text>
          {window.location.host.includes('pathfinder-rebuild.devserver.london') && (
            <Col padding={4} backgroundColor="red" middle>
              <Text fontSize={13} colorWhite style={{ fontStyle: "italic" }}>This is not the <Text colorWhite bold>Product</Text> environment</Text>
            </Col>
          )}
          {FIELDS.map((field, idx) => {
            return (
              <Col mt2 key={`field${idx}`}>
                <Text fontSize={16} fontWeight='600' mb1>{field.label}</Text>
                <Input
                  value={learner?.[field.id]}
                  onChange={(text) => editLearner(field.id, text)}
                  // placeholder={field.placeholder}
                  dataSet={{ element: `input-${field.id}` }}
                />
              </Col>
            )
          })}
          <Button
            mt2
            solid
            text='Continue'
            width='100%'
            onPress={handleLogin}
            isLoading={loading}
            dataSet={{ element: 'submit-contact-form' }}
          />
        </Col>
      </Col>
    </Col>
  )
}

export default ContactForm;
