import React from 'react';
import { Text, IColProps } from 'components';
import { COLOR } from 'const';
import TouchField from './TouchField';

interface ITagProps extends IColProps {
  text: string;
  selected?: boolean;
  textProps?: any;
}

const Tag = (props: ITagProps) => {
  const { text, selected, textProps, ...restProps } = props;
  const backgroundColor = selected ? COLOR.MAIN : COLOR.GREY2;
  const textColor = selected ? 'white' : COLOR.SUB_TEXT;
  return (
    <TouchField
      height={35}
      borderRadius={8}
      backgroundColor={backgroundColor}
      padding={8}
      {...restProps}
    >
      <Text fontSize={16} color={textColor} center numberOfLines={1} {...textProps}>
        {text}
      </Text>
    </TouchField>
  );
};

export default Tag;
