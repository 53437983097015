import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { useMainStore } from './Main.Store';
import { useLearnerStore } from './Learner.Store';
import { useElementStore } from './Element.Store';
import { usePathfinderStore } from './Pathfinder.Store';
import { useStatementStore } from './Statement.Store';

// default to 'http://localhost:3344'
Client.setHost('http://localhost:3344');

if (window.location.host.includes('pathfinder-rebuild.devserver.london')) {
  Client.setHost('https://api.pathfinder-rebuild.devserver.london');
}

if (window.location.host.includes('harborpathfinder.com')) {
  Client.setHost('https://us.harborpathfinder.com');
}

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  useMainStore,
  useLearnerStore,
  useElementStore,
  usePathfinderStore,
  useStatementStore,
}

export default Store;
