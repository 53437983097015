import React, { useMemo } from 'react';
import { Platform, useWindowDimensions, ScrollView } from 'react-native';
import { COLOR } from 'const';
import Col from './Col';
import Row from './Row';
import Text from './Text';

(() => {
  if (Platform.OS !== 'web') return;
  const style = document.createElement('style')
  style.textContent = `
    ::-webkit-scrollbar {
      height: 5px;
      display: none;
    }
    *:hover::-webkit-scrollbar {
      height: 10px;
    }
    #table-header {
      background-image: linear-gradient(rgba(0, 170, 180, 0.4), rgba(0, 170, 180, 0));
    }
  `;
  document.head.append(style);
})();

export interface IColProps {
  key: string, title?: string, flex?: number, width?: number, padding?: number, center?: boolean, noLeftBorder?: boolean,
}

interface ITableProps {
  columns: Array<IColProps>
  data: Array<{ [key: string]: any }>
  minWidthRequired?: number
  [anyProp: string]: any,
}

const Table = (props: ITableProps) => {
  const { width } = useWindowDimensions();
  const { columns, data, minWidthRequired } = props;
  const isScroll = width <= minWidthRequired;

  const mainContent = useMemo(() => {
    return (
      <Col borderRadius={8} overflow='hidden'>
        <Row stretch>
          {columns.map((col, i) => (
            <Col
              width={col.width} flex={col.flex} key={`col${i}`} paddingVertical={21}
              borderLeftWidth={(!col.noLeftBorder && i !== 0) && 1} paddingHorizontal={col.padding || 20}
              middle={col.center} nativeID='table-header' borderLeftColor={COLOR.WHITE}
            >
              <Text fontWeight={'500'} fontSize={16} color={COLOR.MAIN_TEXT}>{col.title || ''}</Text>
            </Col>
          ))}
        </Row>
        {data.map((row, i) => (
          <Row
            nativeID={'table-row'} key={`row${i}`}
            alignItems='center' stretch
            backgroundColor={i % 2 ? 'rgba(242, 248, 249, 1)' : undefined}
          >
            {columns.map((col, j) => {
              let content = <Text></Text>;
              if (typeof row[col.key] === 'string') content = <Text>{row[col.key]}</Text>;
              else if (row[col.key]) content = row[col.key];
              return (
                <Col
                  width={col.width} flex={col.flex} key={`cel${i}${j}`}
                  paddingVertical={8} paddingHorizontal={col.padding || 20}
                  middle={col.center} borderColor={COLOR.MAIN}
                >
                  {content}
                </Col>
              )
            })}
          </Row>
        ))}
      </Col>
    )
  }, [columns, data])

  if (isScroll) {
    return (
      <ScrollView horizontal>
        {mainContent}
      </ScrollView>
    );
  }
  return mainContent;
};

export default Table;
