export * from './screens';

export const FONT = {
  defaultFont: 'Roboto, sans-serif',
  bold: 'Roboto, sans-serif',
  light: 'Roboto, sans-serif',
};

export const COLOR = {
  FONT: 'black',
  WHITE: 'white',
  // MAIN: '#003846',
  MAIN: '#35b3ae',
  BORDER: '#707070',
  BLUE: '#005EB8',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#49D009',
  GREY: '#B9B9B9',
  GREY_LIGHT: '#F0F4F6',
  MAIN_TEXT: '#052728',
  SUB_TEXT: '#477B7E',
  BORDER2: '#CDD4D4',
  GREY2: '#CDD4D466',
  SUCCESS: '#00A053',
  DIVIDER: '#D9D9D9',
  BG: '#E9EFEF',
  RED: 'red',
}

export const INCH_TO_MM = 25.4;
