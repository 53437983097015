import create from 'zustand';
import { TStatement } from 'type';
import Store from 'store';
import _ from 'lodash';

type StatementItem = TStatement & {
  isNew?: boolean
}

interface IStatementStore {
  statements: StatementItem[],
  loading: boolean,
  errors: Error,
  set: (data: any) => void,
  setLoading: (value: boolean) => void,
  getStatements: (pathfinderId: string, elementId: string) => void,
}

export const useStatementStore = create<IStatementStore>((set, get) => ({
  statements: [],
  loading: false,
  errors: null,
  set,
  setLoading: (value) => {
    set({ loading: value })
  },
  getStatements: async (pathfinderId, elementId) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Statement.list({ pathfinderId, elementId });
      if (res.data.data && res.data.success) {
        set({
          statements: res.data.data,
        });
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
}));
