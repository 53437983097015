import { Text, Row, Col, Tag, TouchField } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { COLOR } from 'const';
import { Animated, LayoutChangeEvent } from 'react-native';

interface Props {
  filterKeys: string[]
  selectedKeys: string[]
  onChange: (data: string[]) => void
  visibile: boolean
}

const MARGIN_BOTTOM = 16

const SelectFilter = (props: Props) => {
  const { filterKeys, selectedKeys, onChange, visibile } = props
  const animatedOffset = useRef(new Animated.Value(0))
  const [filterHeight, setFilterHeight] = useState(0)

  useEffect(() => {
    if (visibile) {
      Animated.timing(animatedOffset.current, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedOffset.current, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [visibile])

  const selectFilterKey = useCallback((key) => {
    let arr = [...selectedKeys];
    if (arr.includes(key)) {
      arr = arr.filter(i => i !== key);
    } else {
      arr = [...arr, key];
    }
    onChange(arr);
  }, [selectedKeys, onChange]);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setFilterHeight(e.nativeEvent.layout.height + MARGIN_BOTTOM)
  }, [])

  return (
    <Animated.View style={{
      height: animatedOffset.current.interpolate({
        inputRange: [0, 1],
        outputRange: [0, filterHeight],
      }),
      opacity: animatedOffset.current.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      })
    }}>
      <Col onLayout={onLayout} borderRadius={8} bgWhite borderWidth={1} borderColor={COLOR.BORDER2} padding={16} paddingBottom={4}>
        <Row alignItems={'flex-start'}>
          <Row flexWrap={'wrap'} flex1>
            {filterKeys.map((tag, idx) => {
              const selected = selectedKeys.includes(tag);
              return (
                <Tag
                  text={tag} key={`filter${idx}${selected}`} marginRight={12} marginBottom={12}
                  selected={selected} dataSet={{ element: `filter-key-${idx}` }}
                  onPress={() => selectFilterKey(tag)}
                />
              )
            })}
          </Row>
          {selectedKeys?.length > 0 &&
            <TouchField onPress={() => onChange([])} padding={2} paddingRight={0}>
              <Text fontWeight={'500'} fontSize={16} color={COLOR.MAIN}>Clear all filters</Text>
            </TouchField>
          }
        </Row>
      </Col>
    </Animated.View>
  )
}

export default SelectFilter;
