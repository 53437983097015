import create from 'zustand';
import apiClient from './api-client';
import Client from './api-client';

interface ISiteInfo {
  slug?: string,
  region?: string,
  apiUrl?: string,
  url?: string,
}

interface IMainStore {
  client: ISiteInfo,
  getSiteInfo: (slug: string) => Promise<ISiteInfo>,
  [otherKey: string]: any,
}

export const useMainStore = create<IMainStore>((set, get) => ({
  client: {},
  getSiteInfo: async (slug: string) => {
    const res = await apiClient.Api.Client.siteInfo({ slug });

    if (!res.data.data) return alert("Load Site Info failed");

    let apiUrl = res.data.data.apiUrl;
    if (apiUrl[apiUrl.length - 1] === "/") apiUrl = apiUrl.substring(0, apiUrl.length - 1)

    Client.setHost(apiUrl);

    set({
      client: {
        ...res.data.data,
        apiUrl,
      }
    });
    return res.data.data;
  }
}));
