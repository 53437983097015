import create from 'zustand';
import { TElement } from 'type';
import Store from 'store';
import _ from 'lodash';

type ElementItem = TElement & {
  isNew?: boolean
}

interface IElementStore {
  elements: ElementItem[],
  filterKeys: string[],
  loading: boolean,
  errors: Error,
  set: (data: any) => void,
  setLoading: (value: boolean) => void,
  getElements: (pathfinderId: string) => void,
}

export const useElementStore = create<IElementStore>((set, get) => ({
  elements: [],
  filterKeys: [],
  loading: false,
  errors: null,
  set,
  setLoading: (value) => {
    set({ loading: value })
  },
  getElements: async (pathfinderId) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Element.list({ pathfinderId });
      if (res.data.data && res.data.success) {
        const filterKeys = [];
        for (let element of res.data.data) {
          element?.filterKeys?.split(',').forEach(i => {
            if (i && !filterKeys.includes(i.trim())) {
              filterKeys.push(i.trim());
            }
          });
        }
        set({
          elements: res.data.data.sort(
            (a, b) => (a.orderIndex || 0) - (b.orderIndex || 0)
          ),
          filterKeys,
        });
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
}));
